/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  StaticImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Link from "../components/link/link"
import Metadata, { buildSocialImage } from "../components/metadata/metadata"
import SquareList from "../components/list-square/list-square"
import SectionTitle from "../components/section-title/section-title"
import { colors } from "../gatsby-plugin-theme-ui"
import Integration from "../images/inline-svg/integration.svg"

export default function Platform({ data }) {
  return (
    <Layout>
      <Metadata
        title="Platform"
        // description="Moth+Flame is an award-winning developer of immersive training technology, pioneering the next decade of virtual reality learning for the private and public sector."
        // image={socialImage}
      />
      <Panel
        sx={{
          pt: ["4rem", "6rem", "8rem"],
          bg: "bgGray",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "2", "3"],
            gridColumnEnd: ["-1", "span 6", "span 8"],
            mx: [null, "6rem", "1rem"],
            mb: "4rem",
          }}
        >
          <div sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
            <Themed.h1 className="scaleText" sx={{ fontSize: "5rem" }}>
              Streamline readiness in 3 easy steps
            </Themed.h1>
            <Themed.p sx={{ fontSize: "2rem" }}>
              Create and deploy content, then see AI insights.
            </Themed.p>
          </div>
        </div>
        <StaticImage
          src="../images/product-features.png"
          sx={{ maxWidth: "1600px", mb: "5rem" }}
        />
      </Panel>
      <Panel sx={{ py: ["4rem", "6rem", "8rem"] }} id="analytics-and-risk">
        <SectionTitle sx={{ gridColumn: "1 / -1" }} center>
          Analytics and Risk Visualization
        </SectionTitle>
        <div
          sx={{
            gridColumnStart: ["1"],
            gridColumnEnd: ["span 6", "span 8", "span 12"],
            mb: ["4rem", "unset"],
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{ "--max-font-size": [1.6, 2.8, 5.7] }}
          >
            <em>Visualize and act on readiness risks</em>
          </Themed.h1>
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <Themed.p>
              Uncover critical gaps with data-driven organizational insights.
            </Themed.p>
          </div>
          <div>
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            ></div>
            <StaticImage
              alt="New training methods"
              sx={{
                maxWidth: ["40rem", null, "90rem"],
              }}
              src="../images/org-group-insights.png"
            />
          </div>
        </div>
      </Panel>
      <Panel
        sx={{
          pb: ["3rem", "6rem", "8rem"],
          pt: [null, "6rem", "8rem"],
          bg: "bgGray",
        }}
      >
        <SectionTitle sx={{ gridColumn: "1 / -1" }} left>
          Integration & Adaptability
        </SectionTitle>
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "130rem",
          }}
        >
          <div sx={{ flex: 1, pr: ["0", "2rem"] }}>
            <Themed.h1
              className="scaleText"
              sx={{
                fontFamily: "Montserrat",
                "--max-font-size": [2.2, 3.2, 5.2],
                fontWeight: "600",
                mt: "10rem",
              }}
            >
              <em>Seamless integration with your existing systems</em>
            </Themed.h1>
            <Themed.p
              sx={{
                fontSize: ["1.1rem", "1.2rem", "3rem"],
                lineHeight: [1.2, 1.4, 1.6],
                mt: "6rem",
                mb: "6rem",
              }}
            >
              Flexible solutions that adapt to your operating environment
            </Themed.p>
          </div>
          <StaticImage
            src="../images/integration-data.png"
            placeholder="none"
            alt="M+F data"
            sx={{ maxWidth: "60rem" }}
          />
        </div>
      </Panel>
    </Layout>
  )
}
